/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react'
import Layout from './layout'
import { default as redirects } from '../../static/_permanent_redirects.json'
import { navigate, withAssetPrefix } from 'gatsby'
import { sortBy } from 'lodash'
import { Row, Col, Button } from '@paypalcorp/pp-react'
import { TaggedCard } from '../components/layout/TaggedCard'
import styles from './404.module.css'
import { postRequest } from '../utils/request'

const Error = ({ location }) => {
  const [isError, setError] = useState(false)
  const [algoliaSuggestions, setAlgoliaSuggestions] = useState(null)
  const [popularSuggestions, setPopularSuggestions] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [showResults, setShowResults] = useState(false)
  const [showPopular, setPopular] = useState(false)

  useEffect(() => {
    let matchedPath = Object.keys(redirects).filter((path) => {
      const newPath = path.endsWith('/') ? path.slice(0, -1) : path
      if (location.pathname.startsWith(newPath)) {
        return path
      }
    })

    matchedPath = sortBy(matchedPath, (path) => -path.length)

    if (matchedPath.length > 0) {
      navigate(redirects[matchedPath[0]])
    } else {
      setError(true)

      async function postRequests() {
        const searchParams = new URLSearchParams(location.search)
        const algoliaSearchKeys =
          searchParams.get('q')?.split('/').filter(Boolean) || ''

        const pathSearchKeys = location.pathname.split('/').filter(Boolean)
        const searchKeys = algoliaSearchKeys || pathSearchKeys

        const search = searchKeys.join('%20')
        setSearchTerm(searchKeys.join(', '))

        const algolia = {
          key: '1ea4619b306d3cdd11d0095d42001f67',
          id: 'GBB2ADSCIQ',
          indexName: 'prod_DevRx_docs',
        }
        const headers = {
          'Content-Type': 'application/json',
          'X-Algolia-Application-Id': algolia.id,
          'X-Algolia-API-Key': algolia.key,
        }
        const data = {
          params: `query=${search}&hitsPerPage=6`,
        }
        const data2 = {
          params: `filters=contentType%3A%22API%22+OR+contentType%3A%22Docs%22&hitsPerPage=12`,
        }
        const disableCSRF = true
        try {
          const resp1 = await postRequest(
            `https://${algolia.id}-dsn.algolia.net/1/indexes/${algolia.indexName}/query/`,
            data,
            headers,
            disableCSRF,
          )
          const resp2 = await postRequest(
            `https://${algolia.id}-dsn.algolia.net/1/indexes/${algolia.indexName}/query/`,
            data2,
            headers,
            disableCSRF,
          )
          if (resp1.data && resp2.data) {
            const searchRes = []
            resp1.data.hits.map((x) =>
              searchRes.filter((a) => a.path === x.path).length > 0
                ? null
                : searchRes.push(x),
            )
            setAlgoliaSuggestions(searchRes)
            const popRes = []
            resp2.data.hits.map((x) =>
              popRes.filter((a) => a.path === x.path).length > 0
                ? null
                : popRes.push(x),
            )
            setPopularSuggestions(popRes)
          }
        } catch (error) {
          /* eslint-disable no-console */
          console.log('=== Error ===', error)
          /* eslint-enable no-console */
        }
      }
      postRequests()
    }
  }, [location.pathname])
  return (
    <Layout inverseHeaderScheme={true}>
      {isError && (
        <section className={styles.wrapper}>
          <div className={styles.header} style={{ marginTop: '4rem' }}>
            <div className={styles.title}>
              <h1 className={styles.titleText1}>#404</h1>
              <h1 className={styles.titleText2}>
                Oops, we can’t find that page!
              </h1>
              <div className={styles.buttons}>
                <Button
                  size="md"
                  primary
                  style={{ margin: '1rem 0.5rem 0.5rem 0' }}
                  onClick={() => navigate('/')}
                >
                  Go to Homepage
                </Button>
                <Button size="md" secondary onClick={() => navigate(-1)}>
                  Go Back
                </Button>
              </div>
            </div>
            <img
              src={withAssetPrefix('/img/error-search.svg')}
              alt="HTTP error 404 — Page Not Found"
              className={styles.errorImg}
            />
          </div>
          <div style={{ width: '100%' }}>
            {algoliaSuggestions && algoliaSuggestions.length > 0 && (
              <div className={styles.header}>
                <p className={styles.searchSuggestion}>
                  Search results for '
                  <span className={styles.searchTerm}>{searchTerm}</span>'
                </p>
                {algoliaSuggestions && algoliaSuggestions.length > 3 && (
                  <p
                    className={styles.expand}
                    onClick={() => {
                      setShowResults(!showResults)
                    }}
                  >
                    {showResults ? 'Hide' : 'Show All'}
                  </p>
                )}
              </div>
            )}
            <Row>
              {algoliaSuggestions?.map(
                (result, index) =>
                  (showResults || index < 3) && (
                    <Col sm={4} key={index}>
                      <TaggedCard
                        title={
                          result.title.length < 30
                            ? result.title
                            : result.title.slice(0, 30) + '...'
                        }
                        contentTag={result.contentType}
                        productTag={result.productStatus}
                        description={
                          result.display_description.slice(0, 70) + '...'
                        }
                        link={result.url}
                        linkText="View page"
                      />
                    </Col>
                  ),
              )}
            </Row>
            <div className={styles.header}>
              <p className={styles.searchSuggestion}>
                Popular links for 'Developer'
              </p>
              {popularSuggestions && popularSuggestions.length > 3 && (
                <p
                  className={styles.expand}
                  onClick={() => {
                    setPopular(!showPopular)
                  }}
                >
                  {showPopular ? 'Hide' : 'Show All'}
                </p>
              )}
            </div>
            <Row>
              {popularSuggestions?.map(
                (result, index) =>
                  (showPopular || index < 3) && (
                    <Col sm={4} key={index}>
                      <TaggedCard
                        title={
                          result.title.length < 30
                            ? result.title
                            : result.title.slice(0, 30) + '...'
                        }
                        contentTag={result.contentType}
                        productTag={result.productStatus}
                        description={
                          result.display_description.slice(0, 70) + '...'
                        }
                        link={result.url}
                        linkText="View page"
                      />
                    </Col>
                  ),
              )}
            </Row>
          </div>
        </section>
      )}
    </Layout>
  )
}

export default Error
