import React from 'react'
import styles from './tagged-card.module.css'

interface TaggedCardProps {
  title: string
  contentTag: string
  productTag: string
  description: string
  link: string
  linkText: string
  asLink?: any
}

type As<Props = any> = React.ElementType<Props>

export const TaggedCard = (props: TaggedCardProps) => {
  const {
    title,
    contentTag,
    productTag,
    description,
    link,
    linkText,
    asLink: As,
  } = props

  const Element = As || 'a'

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.tags}>
          {contentTag && <div className={styles.contentTag}>{contentTag}</div>}
          {productTag && <div className={styles.productTag}>{productTag}</div>}
        </div>
        <h1 className={styles.title}>{title}</h1>
      </div>

      <p className={styles.description}>{description}</p>

      <Element classname={styles.link} href={link}>
        {linkText}
      </Element>
    </div>
  )
}
